import React from 'react';

import MsftSignIn from '@/components/commons/icons/MsftSignIn';

function SignInWithMsft({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className, signup = false,
}) {
    // TODO: Implement Sign-up vs Sign-in
    return (
        <div className={className}>
            <MsftSignIn />
        </div>
    );
}

export default SignInWithMsft;
