import React, { ReactElement } from 'react';

import styles from './Panel.module.css';

interface Props {
    readonly align?: string;
    readonly children?: ReactElement | Array<ReactElement>;
}

export default function Header({ align = 'center', children = <div /> }: Props) {
    return (
        <div className={styles.headerContainer}>
            <h2 className={align === 'left' ? styles.headerContentLeft : styles.headerContentLeft}>
                {children}
            </h2>
        </div>
    );
}
