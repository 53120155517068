import React from 'react';

import styles from './Panel.module.css';

function Panel({ width = 'wide', children }) {
    let innerClass: string;
    switch (width) {
    case 'wide':
        innerClass = styles.innerContainerWide;
        break;
    case 'medium':
        innerClass = styles.innerContainerMedium;
        break;
    default:
        innerClass = styles.innerContainerNarrow;
    }

    return (
        <div className={styles.backgroundContainer}>
            <div className={styles.outerContainer}>
                <div className={styles.middleContainer}>
                    <div className={innerClass}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Panel;
