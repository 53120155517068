import { gql } from '@apollo/client';

import { COMMON_USER_FIELDS, COMMON_ACCOUNT_FIELDS, COMMON_CALENDAR_INSTANCE_FIELDS } from '@/types/globals';

export const KIND_OPTIONS = [
    { value: 'professional', label: 'Professional' },
    { value: 'personal', label: 'Personal' },
];

export const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount($updateAccountInput: UpdateAccountInput!) {
        updateAccount(input: $updateAccountInput) {
            account {
                ${COMMON_ACCOUNT_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

export const UPDATE_PRIMARY_ACCOUNT = gql`
    mutation UpdatePrimaryAccount(
        $updateUserInput: UpdateUserInput!
        $sortColumn: AccountColumnsEnum
        $sortDirection: SortDirectionEnum
    ) {
        updateUser(input: $updateUserInput) {
            user {
                ${COMMON_USER_FIELDS}

                accounts(
                    sortColumn: $sortColumn
                    sortDirection: $sortDirection
                ) {
                    ${COMMON_ACCOUNT_FIELDS}
                }
            }
            errors {
                path
                message
            }
        }
    }
`;

export const FETCH_ACCOUNTS = gql`
    query FetchAccounts(
        $id: ID
        $sortColumn: AccountColumnsEnum
        $sortDirection: SortDirectionEnum
    ) {
        user {
            accounts(
                id: $id
                sortColumn: $sortColumn
                sortDirection: $sortDirection
            ) {
                ${COMMON_ACCOUNT_FIELDS}
            }
        }
    }
`;

export const DELETE_ACCOUNT = gql`
    mutation DeleteAccount($deleteAccountInput: DeleteAccountInput!) {
        deleteAccount(input: $deleteAccountInput) {
            account {
                id
            }
            errors {
                path
                message
            }
        }
    }
`;

export const FETCH_CALENDAR_INSTANCES = gql`
    query FetchCalendarInstances(
        $id: ID
    ) {
        user {
            calendarInstances(
                id: $id
            ) {
                ${COMMON_CALENDAR_INSTANCE_FIELDS}
            }
        }
    }
`;

export const CATEGORY_OPTIONS = [
    { value: 'professional', label: 'Professional' },
    { value: 'personal', label: 'Personal' },
    { value: 'third_party', label: '3rd Party' },
    { value: 'resource', label: 'Resource' },
    { value: 'travel', label: 'Travel' },
    { value: 'ooo', label: 'Out of Office' },
    { value: 'other', label: 'Other' },
];

export const CATEGORY_LABELS = CATEGORY_OPTIONS
    .reduce((mm, v) => { const nn = mm; nn[v.value] = v.label; return nn; }, {});

export const UPDATE_CALENDAR_INSTANCE = gql`
    mutation UpdateCalendarInstance($updateCalendarInstanceInput: UpdateCalendarInstanceInput!) {
        updateCalendarInstance(input: $updateCalendarInstanceInput) {
            calendarInstances {
                ${COMMON_CALENDAR_INSTANCE_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

export const UPDATE_CALENDAR_INSTANCES = gql`
    mutation UpdateCalendarInstances($updateCalendarInstancesInput: UpdateCalendarInstancesInput!) {
        updateCalendarInstances(input: $updateCalendarInstancesInput) {
            calendarInstances {
                ${COMMON_CALENDAR_INSTANCE_FIELDS}
            }
            errors {
                path
                message
            }
        }
    }
`;

export const CREATE_MAGIC_LINK = gql`
    mutation CreateMagicLink($createMagicLinkInput: CreateMagicLinkInput!) {
        createMagicLink(input: $createMagicLinkInput) {
            errors {
                path
                message
            }
        }
    }
`;
