import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import { sentenceCase } from 'change-case';
import router from 'next/router';

import routes from '@/services/routes';
// import Input from '@/components/forms/input';
import Button, { ButtonSize, ButtonType } from '@/components/commons/button';
import { handleFetchErrors } from '@/services/apollo';
import WarningCard from '@/components/commons/warning_card';
import { CREATE_MAGIC_LINK } from '@/components/accounts/Actions';
import WandMagicSparkles from '@/components/commons/icons/WandMagicSparkles';
import styles from '@/components/login/Login.module.css';
import EnvelopeIcon from './EnvelopeIcon';
import Input from './Input';

interface Props {
    readonly analytics?: { sourceKey?: string, benefitsKey?: string };
}

function MagicLinkForm({
    analytics = { sourceKey: null, benefitsKey: null },
}: Props) {
    const [formError, setFormError] = useState('');

    const [createMagicLink] = useMutation(CREATE_MAGIC_LINK);
    const handleCreateMagicLink = async (email) => {
        try {
            const { sourceKey, benefitsKey } = analytics;

            const response = await createMagicLink({
                variables: {
                    createMagicLinkInput: {
                        email,
                        sourceKey,
                        benefitsKey,
                    },
                },
            });

            if (response.data.createMagicLink.errors.length === 0) {
                if (formError !== '') setFormError('');

                router.push(routes.MAGIC_LINK_SENT);

                return;
            }

            // TODO: This will look like garbage if there's multiple errors.  That shouldn't really
            // TODO: happen, but we should handle it better if it does.
            const messages = [];
            response.data.createMagicLink.errors.forEach((error) => {
                const fieldRaw = error.path[error.path.length - 1];
                const fieldName = sentenceCase(fieldRaw, { stripRegexp: /^$/ });
                messages.push(`${fieldName} ${error.message}.`);
            });

            setFormError(messages.join('\n'));
        } catch (err) {
            handleFetchErrors(err);
            setFormError('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <div className={styles.emailContainer}>

            <Formik
                onSubmit={async (values) => {
                    await handleCreateMagicLink(values.email);
                }}
                initialValues={{ email: '' }}
            >
                {(_props) => (
                    <Form className={`flex flex-col gap-6 w-full  ${styles.magicLinkForm}`}>

                        <Field
                            id="email"
                            name="email"
                            component={Input}
                            icon={EnvelopeIcon}
                            sizing="md"
                            placeholder="What is your email address?"
                            className={styles.emailInput}
                        />

                        {
                            formError !== '' && (
                                <div className="w-full my-[20px]">
                                    <WarningCard message={formError} />
                                </div>
                            )
                        }

                        <Button
                            type={ButtonType.submit}
                            text="Get your magic link"
                            size={ButtonSize.base}
                            externalStyles="min-w-[100px] w-full h-[40px] text-center flex justify-center items-center"
                            icon={<WandMagicSparkles className="h-[12px] w-[12px] mb-[1px] text-white" />}
                        />

                    </Form>
                )}
            </Formik>

        </div>
    );
}

export default MagicLinkForm;
